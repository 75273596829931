import React, { useContext } from 'react';
import { Route } from 'react-router-dom';

import { HashContext } from 'providers/HashProvider';

const AppRoute = ({ component: Component, layout, info, highlightHeader, closeHighlightHeader, ...rest }) => {
  const Layout = layout === undefined ? (props) => <>{props.children}</> : layout;
  const { darkTheme } = useContext(HashContext);
  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          <div className={darkTheme ? 'theme--dark' : 'theme--default'}>
            <Layout info={info}>
              <Component
                {...props}
                info={info}
                highlightHeader={highlightHeader}
                closeHighlightHeader={closeHighlightHeader}
              />
            </Layout>
          </div>
        );
      }}
    />
  );
};

export default AppRoute;
