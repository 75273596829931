/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const leaderboardSlice = createSlice({
  name: 'leaderboard',
  initialState: {
    offset: 0,
    list: [],
    totalPage: 0,
  },
  reducers: {
    setPlayersGGScore: (state, { payload }) => {
      state.list = payload;
    },
    setOffset: (state, { payload }) => {
      state.offset = payload;
    },
    setPlayersGGScoreCount: (state, { payload }) => {
      state.totalPage = payload;
    },

    resetLeaderboardReducer: (state) => {
      state.offset = 0;
      state.list = [];
      state.totalPage = 0;
    },
  },
});

export const { setPlayersGGScore, setPlayerGGScore, setOffset, setPlayersGGScoreCount } = leaderboardSlice.actions;
export default leaderboardSlice.reducer;
