import React from 'react';
import PropTypes from 'prop-types';
import { throttle } from 'lodash';

class ScrollReveal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      viewportHeight: window.innerHeight,
      revealEl: [],
    };
  }

  handleListeners = () => {
    if (!this.checkComplete()) {
      return;
    }
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.handleResize);
  };

  handleScroll = throttle(() => {
    this.handleListeners();
    this.revealElements();
  }, 30);

  handleResize = throttle(() => {
    this.setState({ viewportHeight: window.innerHeight }, () => {
      this.handleListeners();
      this.revealElements();
    });
  }, 30);

  init = () => {
    setTimeout(() => {
      this.setState({ revealEl: document.querySelectorAll('[class*=reveal-]') }, () => {
        if (!this.checkComplete()) {
          window.addEventListener('scroll', this.handleScroll);
          window.addEventListener('resize', this.handleResize);
        }
        this.revealElements();
      });
    }, 100);
  };

  checkComplete = () => {
    return this.state.revealEl.length <= document.querySelectorAll('[class*=reveal-].is-revealed').length;
  };

  elementIsVisible = (el, offset) => {
    return el.getBoundingClientRect().top <= this.state.viewportHeight - offset;
  };

  revealElements = () => {
    if (this.checkComplete()) {
      return;
    }
    for (let i = 0; i < this.state.revealEl.length; i += 1) {
      const el = this.state.revealEl[i];
      const revealDelay = el.getAttribute('data-reveal-delay');
      const revealOffset = el.getAttribute('data-reveal-offset') ? el.getAttribute('data-reveal-offset') : '200';
      const listenedEl = el.getAttribute('data-reveal-container')
        ? el.closest(el.getAttribute('data-reveal-container'))
        : el;
      if (this.elementIsVisible(listenedEl, revealOffset) && !el.classList.contains('is-revealed')) {
        if (revealDelay && revealDelay !== 0) {
          setTimeout(() => {
            el.classList.add('is-revealed');
          }, revealDelay);
        } else {
          el.classList.add('is-revealed');
        }
      }
    }
  };

  render() {
    return <>{this.props.children()}</>;
  }
}

ScrollReveal.propTypes = {
  children: PropTypes.func.isRequired,
};

export default ScrollReveal;
