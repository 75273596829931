import { UDConnector } from './UDConnector';

export const UnstoppableDomains = ({ chains }) => ({
  id: 'unstoppableDomains',
  name: 'Unstoppable Domains',
  iconUrl: 'https://avatars.githubusercontent.com/u/36172275?s=280&v=4',
  iconBackground: '#0c2f78',
  downloadUrls: {
    android: 'https://unstoppabledomains.com/',
    ios: 'https://unstoppabledomains.com/',
    qrCode: 'https://unstoppabledomains.com/',
  },
  createConnector: () => {
    const udConnector = new UDConnector({ chains });

    return {
      connector: udConnector,
      desktop: {
        getUri: async () => {
          const user = await udConnector.getUDUser();

          if (!user?.wallet_type_hint) {
            throw new Error('No wallet type present');
          }

          if (user.wallet_type_hint === 'walletconnect') {
            const { uri } = (await udConnector.getProvider()).connector;
            return uri;
          }
          return undefined;
        },
      },
    };
  },
});
