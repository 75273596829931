/* eslint-disable no-param-reassign */
import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { setLoadingState } from './gamestates';
import {
  setLoaderApproveDeposit,
  setLoaderJoinGame,
  setLoaderWithdraw,
  setLoaderEarlyWithdraw,
  setLoaderMakeDeposit,
} from './feedbacks';

export const walletSlice = createSlice({
  name: 'wallet',
  initialState: {
    isUsingValora: false,
    isValoraModalOpen: false,
  },
  reducers: {
    setIsUsingValora: (state, { payload }) => {
      state.isUsingValora = payload;
    },
    setIsValoraModalOpen: (state, { payload }) => {
      state.isValoraModalOpen = payload;
    },
  },
  extraReducers: (builder) =>
    builder
      // For V1 components
      .addCase(setLoadingState, (state, { payload }) => {
        if (state.isUsingValora) {
          const hasPendingTransaction = Object.values(payload).includes(true);

          if (hasPendingTransaction) {
            state.isValoraModalOpen = true;
          } else {
            state.isValoraModalOpen = false;
          }
        }
      })
      // For V2 SDK components
      .addMatcher(
        isAnyOf(
          setLoaderApproveDeposit,
          setLoaderJoinGame,
          setLoaderWithdraw,
          setLoaderEarlyWithdraw,
          setLoaderMakeDeposit
        ),
        (state, { payload }) => {
          if (state.isUsingValora) {
            const hasPendingTransaction = payload;

            if (hasPendingTransaction) {
              state.isValoraModalOpen = true;
            } else {
              state.isValoraModalOpen = false;
            }
          }
        }
      ),
});

export const { setIsUsingValora, setIsValoraModalOpen } = walletSlice.actions;
export default walletSlice.reducer;
