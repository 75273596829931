/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const feedbacksSlice = createSlice({
  name: 'feedbacks',
  initialState: {
    customInput: false,
    revokeApproval: false,
    // loaders
    loaderApproveDeposit: false,
    loaderEarlyWithdraw: false,
    loaderGetPlayer: false,
    loaderInitGame: true,
    loaderJoinGame: false,
    loaderMakeDeposit: false,
    loaderUpdatingGame: false,
    loaderWithdraw: false,
    loaderGasPrices: false,
    // errors
    errorGeneral: false,
    errorNotWhitelisted: false,
    errorRedeem: false,
    errorDepositIntoExternalPool: false,
    errorTransaction: false,
    errorTransactionMessage: '',
    errorRefusedSignature: false,
    // success
    successApproveDeposit: false,
    successJoinGame: false,
    successEarlyWithdraw: false,
    successMakeDeposit: false,
    successWithdraw: false,
    successRedeem: false,
    successDepositIntoExternalPool: false,
    // modals
    showConfirmModal: false,
    showFlexibleDepositModal: false,
    showCustomiseAvatarModal: false,
    showCustomiseAvatarThankYouModal: false,
    showDepositModal: false,
    showEarlyWithdrawModal: false,
    showWithdrawModal: false,
    showDisclaimerModal: false,
  },
  reducers: {
    setCustomInput: (state, { payload }) => {
      state.customInput = payload;
    },
    setRevokeApproval: (state, { payload }) => {
      state.revokeApproval = payload;
    },
    setLoaderInitGame: (state) => {
      state.loaderInitGame = false;
    },
    setLoaderApproveDeposit: (state, { payload }) => {
      state.loaderApproveDeposit = payload;
    },
    setLoaderJoinGame: (state, { payload }) => {
      state.loaderJoinGame = payload;
    },
    setLoaderWithdraw: (state, { payload }) => {
      state.loaderWithdraw = payload;
    },
    setLoaderEarlyWithdraw: (state, { payload }) => {
      state.loaderEarlyWithdraw = payload;
    },
    setLoaderUpdatingGame: (state, { payload }) => {
      state.loaderUpdatingGame = payload;
    },
    setLoaderMakeDeposit: (state, { payload }) => {
      state.loaderMakeDeposit = payload;
    },
    setLoaderGasPrices: (state, { payload }) => {
      state.loaderGasPrices = payload;
    },
    setLoaderGetPlayer: (state, { payload }) => {
      state.loaderGetPlayer = payload;
    },
    setErrorGeneral: (state, { payload }) => {
      state.errorGeneral = payload;
    },
    setErrorNotWhitelisted: (state, { payload }) => {
      state.errorNotWhitelisted = payload;
    },
    setErrorRedeem: (state, { payload }) => {
      state.errorRedeem = payload;
    },
    setErrorDepositIntoExternalPool: (state, { payload }) => {
      state.errorDepositIntoExternalPool = payload;
    },
    setErrorTransaction: (state, { payload }) => {
      state.errorTransaction = payload;
      if (payload === false) {
        state.errorTransactionMessage = '';
      }
    },
    setErrorTransactionMessage: (state, { payload }) => {
      state.errorTransactionMessage = payload;
    },
    setErrorRefusedSignature: (state, { payload }) => {
      state.errorRefusedSignature = payload;
    },
    setSuccessApproveDeposit: (state, { payload }) => {
      state.successApproveDeposit = payload;
    },
    setSuccessJoinGame: (state, { payload }) => {
      state.successJoinGame = payload;
    },
    setSuccessEarlyWithdraw: (state, { payload }) => {
      state.successEarlyWithdraw = payload;
    },
    setSuccessMakeDeposit: (state, { payload }) => {
      state.successMakeDeposit = payload;
    },
    setSuccessWithdraw: (state, { payload }) => {
      state.successWithdraw = payload;
    },
    setSuccessRedeem: (state, { payload }) => {
      state.successRedeem = payload;
    },
    setSuccessDepositIntoExternalPool: (state, { payload }) => {
      state.successDepositIntoExternalPool = payload;
    },
    setShowConfirmModal: (state, { payload }) => {
      state.showConfirmModal = payload;
    },
    setShowFlexibleDepositModal: (state, { payload }) => {
      state.showFlexibleDepositModal = payload;
    },
    setShowCustomiseAvatarModal: (state, { payload }) => {
      state.showCustomiseAvatarModal = payload;
    },
    setShowDisclaimerModal: (state, { payload }) => {
      state.showDisclaimerModal = payload;
    },
    setShowCustomiseAvatarThankYouModal: (state, { payload }) => {
      state.showCustomiseAvatarThankYouModal = payload;
    },
    setShowDepsitModal: (state, { payload }) => {
      state.showDepositModal = payload;
    },
    setShowEarlyWithdrawModal: (state, { payload }) => {
      state.showEarlyWithdrawModal = payload;
    },
    setShowWithdrawModal: (state, { payload }) => {
      state.showWithdrawModal = payload;
    },
    resetCustomInput: (state) => {
      state.customInput = false;
    },
    resetErrors: (state) => {
      state.errorGeneral = false;
      state.errorNotWhitelisted = false;
      state.errorRedeem = false;
      state.errorDepositIntoExternalPool = false;
      state.errorTransaction = false;
      state.errorRefusedSignature = false;
    },
    resetSuccess: (state) => {
      state.successApproveDeposit = false;
      state.successJoinGame = false;
      state.successEarlyWithdraw = false;
      state.successMakeDeposit = false;
      state.successWithdraw = false;
      state.successRedeem = false;
      state.successDepositIntoExternalPool = false;
    },
    resetFeedbacks: (state) => {
      state.errorGeneral = false;
      state.loaderInitGame = true;
      state.loaderApproveDeposit = false;
      state.loaderJoinGame = false;
      state.loaderWithdraw = false;
      state.loaderMakeDeposit = false;
      state.loaderGasPrices = false;
      state.loaderEarlyWithdraw = false;
      state.loaderUpdatingGame = false;
      state.loaderGetPlayer = false;
      state.errorNotWhitelisted = false;
      state.errorRedeem = false;
      state.errorDepositIntoExternalPool = false;
      state.errorTransaction = false;
      state.errorRefusedSignature = false;
      state.successApproveDeposit = false;
      state.successJoinGame = false;
      state.successEarlyWithdraw = false;
      state.successMakeDeposit = false;
      state.successWithdraw = false;
      state.successRedeem = false;
      state.successDepositIntoExternalPool = false;
      state.showConfirmModal = false;
      state.showFlexibleDepositModal = false;
      state.showCustomiseAvatarModal = false;
      state.showCustomiseAvatarThankYouModal = false;
      state.showDepositModal = false;
      state.showEarlyWithdrawModal = false;
      state.showWithdrawModal = false;
      state.showDisclaimerModal = false;
    },
  },
});

export default feedbacksSlice.reducer;
export const {
  setCustomInput,
  setRevokeApproval,
  setLoaderInitGame,
  setLoaderApproveDeposit,
  setLoaderJoinGame,
  setLoaderWithdraw,
  setLoaderEarlyWithdraw,
  setLoaderUpdatingGame,
  setLoaderGetPlayer,
  setLoaderMakeDeposit,
  setLoaderGasPrices,
  setErrorGeneral,
  setErrorNotWhitelisted,
  setErrorRedeem,
  setErrorDepositIntoExternalPool,
  setErrorTransaction,
  setErrorTransactionMessage,
  setErrorRefusedSignature,
  setSuccessApproveDeposit,
  setSuccessJoinGame,
  setSuccessEarlyWithdraw,
  setSuccessMakeDeposit,
  setSuccessWithdraw,
  setSuccessRedeem,
  setSuccessDepositIntoExternalPool,
  setShowConfirmModal,
  setShowFlexibleDepositModal,
  setShowCustomiseAvatarModal,
  setShowDisclaimerModal,
  setShowCustomiseAvatarThankYouModal,
  setShowDepsitModal,
  setShowEarlyWithdrawModal,
  setShowWithdrawModal,
  resetCustomInput,
  resetErrors,
  resetSuccess,
  resetFeedbacks,
} = feedbacksSlice.actions;
