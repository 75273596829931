import React from 'react';
import Header from '../components/layout/Header';

const LayoutDefault = ({ children }) => (
  <>
    <Header
      navPosition="right"
      highlightHeader={children.props.highlightHeader}
      closeHighlightHeader={children.props.closeHighlightHeader}
    />
    <main className="site-content">{children}</main>
  </>
);

export default LayoutDefault;
