/* eslint-disable no-unused-expressions */
import React, { useEffect, useState, useCallback } from 'react';
import { ArcxAnalyticsSdk } from '@arcxmoney/analytics';
import { CHAIN_CHANGED_EVENT } from '@arcxmoney/analytics/dist/cjs/src/constants';

export const AnalyticsProviderContext = React.createContext();

export default function AnalyticsProvider({ children }) {
  const [arcxAnalyticsProvider, setArcxAnalyticsProvider] = useState(undefined);
  // Provision of ArcxAnalytic instance as a singleton
  useEffect(() => {
    async function init() {
      try {
        const enableArcxAnalytics = String(process.env?.REACT_APP_ENABLE_ARCX_ANALYTIC || '').toLowerCase() === 'true';
        if (enableArcxAnalytics) {
          const provider = await ArcxAnalyticsSdk.init(process.env?.REACT_APP_ARCX_ANALYTIC, {
            cacheIdentity: true,
            trackReferrer: true,
            trackPages: true,
            trackUTM: true,
            trackTransactions: false,
            trackWalletConnections: false,
            trackChainChanges: false,
          });
          setArcxAnalyticsProvider(provider);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(`ArcxAnalyticProvider: ${error}`);
      }
    }
    init();
  }, []);

  const connectWallet = useCallback(
    (userAddress, chainId) => {
      try {
        arcxAnalyticsProvider?.connectWallet({ account: userAddress.toString(), chain: chainId });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(`ArcxAnalyticProvider: ${error}`);
      }
    },
    [arcxAnalyticsProvider]
  );

  const sendTransaction = useCallback(
    (networkId, transactionHash, metadata, transactionConfig) => {
      try {
        const { txType, contract, gameName, depositToken, riskProfile, amount, txTimestamp } = metadata;
        arcxAnalyticsProvider?.transaction({
          chain: networkId,
          transactionHash,
          metadata: {
            txType,
            poolAddress: contract,
            gameName,
            depositToken,
            riskProfile,
            amount,
            txTimestamp,
            ...transactionConfig,
          },
        });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(`ArcxAnalyticProvider: ${error}`);
      }
    },
    [arcxAnalyticsProvider]
  );

  const sendEvent = useCallback(
    (chainId, txTimestamp, eventType) => {
      try {
        arcxAnalyticsProvider?.event(eventType, { chain: chainId, txTimestamp });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(`ArcxAnalyticProvider: ${error}`);
      }
    },
    [arcxAnalyticsProvider]
  );

  const changeChain = useCallback(
    (chainId) => {
      try {
        arcxAnalyticsProvider?.event(CHAIN_CHANGED_EVENT, { chain: chainId });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(`ArcxAnalyticProvider: ${error}`);
      }
    },
    [arcxAnalyticsProvider]
  );

  return (
    <AnalyticsProviderContext.Provider value={{ sendTransaction, connectWallet, sendEvent, changeChain }}>
      {children}
    </AnalyticsProviderContext.Provider>
  );
}
