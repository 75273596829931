import React, { useEffect } from 'react';
import { RampInstantSDK } from '@ramp-network/ramp-instant-sdk';
import Modal from 'components/elements/Modal';
import useRampConf, { defaultAsset, sharedRampOpts } from 'views/Buy/components/hooks/useRampConf';

export default function RampModal({ showModal, setShowModal }) {
  const { usersAddress, swapAmount, asset, userConnectedNetworkId, alertMessage } = useRampConf();

  useEffect(() => {
    if (!showModal || !usersAddress) {
      return;
    }
    new RampInstantSDK({
      ...sharedRampOpts,
      ...(swapAmount && { swapAmount }),
      defaultAsset: asset || defaultAsset,
      userAddress: usersAddress,
      containerNode: document.getElementById('ramp-container-modal'),
    })
      .show()
      .on('WIDGET_CLOSE', () => setShowModal(false));
  }, [showModal, usersAddress, userConnectedNetworkId, alertMessage]);

  return (
    <Modal className="ramp-modal" show={showModal} handleClose={() => setShowModal(false)}>
      {alertMessage && <p style={{ color: 'red' }}>{alertMessage}</p>}
      <div className="d-flex justify-content-center">
        <div id="ramp-container-modal" />
      </div>
    </Modal>
  );
}
