import React from 'react';
import ENS, { getEnsAddress } from '@ensdomains/ensjs';
import { providers } from 'ethers';
import { networkData, ethereumID } from 'utils/networks';

export const EnsContext = React.createContext();

export default function EnsProvider({ children }) {
  const getEns = (provider, ensAddress) => {
    let ens;
    if (provider !== undefined && ensAddress !== undefined) {
      ens = new ENS({
        provider,
        ensAddress,
      });
    } else {
      const mainnetProvider = new providers.StaticJsonRpcProvider(networkData[ethereumID].rpcURL);
      ens = new ENS({
        provider: mainnetProvider,
        ensAddress: getEnsAddress('1'),
      });
    }
    return ens;
  };

  async function getName(address, provider, ensAddress, tld) {
    let ens;
    let addressFromName;
    try {
      if (tld !== undefined) {
        ens = getEns(provider, ensAddress);
      } else {
        ens = getEns();
      }
      const ensResult = await ens.getName(address);
      const { name } = ensResult;

      if (tld !== undefined) {
        addressFromName = await ens.name(`${name}.${tld}`).getAddress();
      } else {
        addressFromName = await ens.name(name).getAddress();
      }

      if (addressFromName.toLowerCase() === address.toLowerCase()) {
        return name;
      }
      return address;
    } catch (e) {
      return address;
    }
  }

  async function getAddress(provider, ensAddress, name, tld) {
    let ens;
    if (tld === 'nom') {
      ens = getEns(provider, ensAddress);
    } else {
      ens = getEns();
    }
    return ens.name(name).getAddress();
  }

  const getEnsName = async (address, provider, ensAddress, tld) => {
    if (tld !== undefined) {
      return getName(address, provider, ensAddress, tld);
    }
    return getName(address);
  };

  return <EnsContext.Provider value={{ getEnsName, getAddress }}>{children}</EnsContext.Provider>;
}
