import { configureStore, combineReducers } from '@reduxjs/toolkit';
import storage from 'reduxjs-toolkit-persist/lib/storage';
import autoMergeLevel2 from 'reduxjs-toolkit-persist/lib/stateReconciler/autoMergeLevel2';
import { persistReducer } from 'reduxjs-toolkit-persist';
import gameReducer from './reducers/game';
import gameStatesReducer from './reducers/gamestates';
import poolReducer from './reducers/pool';
import playersReducer from './reducers/players';
import userReducer from './reducers/user';
import leaderboardReducer from './reducers/leaderboard';
// v2
import feedbacksReducer from './reducers/feedbacks';
import pricefeedReducer from './reducers/pricefeed';
import walletReducer from './reducers/wallet';
import dashboardReducer from './reducers/dashboard';

const persistConfig = {
  timeout: 100,
  key: 'root',
  storage,
  blacklist: ['wallet', 'user', 'leaderboard'],
  stateReconciler: autoMergeLevel2,
};

const reducers = combineReducers({
  game: gameReducer,
  gamestates: gameStatesReducer,
  pool: poolReducer,
  players: playersReducer,
  user: userReducer,
  feedbacks: feedbacksReducer,
  pricefeed: pricefeedReducer,
  wallet: walletReducer,
  dashboard: dashboardReducer,
  leaderboard: leaderboardReducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);

export default configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
