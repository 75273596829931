import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
// import { Link } from 'react-router-dom';
// import { HashLink } from 'react-router-hash-link';
import { setIdentityName } from 'redux/reducers/dashboard';
import Logo from './partials/Logo';
import { WalletButton } from './partials/WalletButton';

const propTypes = {
  active: PropTypes.bool,
  navPosition: PropTypes.string,
  hideNav: PropTypes.bool,
  hideSignin: PropTypes.bool,
  bottomOuterDivider: PropTypes.bool,
  bottomDivider: PropTypes.bool,
  closeHighlightHeader: PropTypes.func,
  className: PropTypes.string,
  highlightHeader: PropTypes.bool,
  pathname: PropTypes.string,
  setIdentityName: PropTypes.func,
  userAddress: PropTypes.string,
};

const defaultProps = {
  active: false,
  navPosition: '',
  hideNav: false,
  hideSignin: false,
  bottomOuterDivider: false,
  bottomDivider: false,
  closeHighlightHeader: undefined,
  setIdentityName: undefined,
  userAddress: '',
  className: '',
  highlightHeader: false,
  pathname: '',
};

class Header extends React.Component {
  nav = React.createRef();

  hamburger = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      isActive: false,
      // list of displayIds of the pools that should have a custom header message (emergency situations)
      // set it as [] or undefined to disable it.
      customHeaderPools: [71, 69, 63],
    };
  }

  componentDidMount() {
    if (this.props.active) {
      this.openMenu();
    }
    document.addEventListener('keydown', this.keyPress);
    document.addEventListener('click', this.clickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.keyPress);
    document.addEventListener('click', this.clickOutside);
    this.closeMenu();
  }

  openMenu = () => {
    document.body.classList.add('off-nav-is-active');
    this.nav.current.style.maxHeight = `${this.nav.current.scrollHeight}px`;
    this.setState({ isActive: true });
  };

  closeMenu = () => {
    document.body.classList.remove('off-nav-is-active');
    if (this.nav.current) {
      this.nav.current.style.maxHeight = null;
    }
    this.setState({ isActive: false });
  };

  keyPress = (e) => {
    if (this.state.isActive && e.keyCode === 27) {
      this.closeMenu();
    }
  };

  clickOutside = (e) => {
    if (!this.nav.current) {
      return;
    }
    if (!this.state.isActive || this.nav.current.contains(e.target) || e.target === this.hamburger.current) {
      return;
    }
    this.closeMenu();
  };

  gotoDashboard = () => {
    this.closeMenu();
    const { userAddress } = this.props;
    this.props.setIdentityName({ userAddress, userName: userAddress });
  };

  render() {
    const { className, navPosition, hideNav, hideSignin, bottomOuterDivider, bottomDivider, pathname, ...props } =
      this.props;
    const poolId = pathname ? String(pathname).toLowerCase().substring('/pools/'.length) : undefined;
    const showEmergencyHeader = poolId && this.state?.customHeaderPools?.includes(parseInt(poolId));

    const classes = classNames('site-header', bottomOuterDivider && 'has-bottom-divider', className);

    return (
      <header {...props} className={classes}>
        <div className="container">
          <div className={classNames('site-header-inner', bottomDivider && 'has-bottom-divider')}>
            <Logo />
            {!hideNav && (
              <>
                <button
                  ref={this.hamburger}
                  className="header-nav-toggle"
                  onClick={this.state.isActive ? this.closeMenu : this.openMenu}
                  type="button"
                >
                  <span className="screen-reader">Menu</span>
                  <span className="hamburger">
                    <span className="hamburger-inner" />
                  </span>
                </button>
                <nav ref={this.nav} className={classNames('header-nav', this.state.isActive && 'is-active')}>
                  <div className="header-nav-inner">
                    <ul className={classNames('list-reset text-xxs', navPosition && `header-nav-${navPosition}`)}>
                      <li>
                        <a href="https://app.halofi.me/#" onClick={this.closeMenu}>
                          Pools
                        </a>
                      </li>
                      <li>
                        <a href="https://app.halofi.me/#/dashboard" onClick={this.closeMenu}>
                          Dashboard
                        </a>
                      </li>
                      <li>
                        <a href="https://app.halofi.me/#/buy" onClick={this.closeMenu}>
                          Buy crypto
                        </a>
                      </li>
                      <li>
                        <a href="https://app.halofi.me/#/leaderboard" onClick={this.closeMenu}>
                          Leaderboard
                        </a>
                      </li>
                      <li>
                        <a href="https://app.halofi.me" onClick={this.closeMenu}>
                          FAQ
                        </a>
                      </li>
                      {/* <li>
                        <a
                          href="https://goodghosting.com/vote"
                          target="_blank"
                          onClick={this.closeMenu}
                          rel="noreferrer"
                        >
                          Vote
                        </a>
                      </li>  */}
                      <li>
                        <a href="https://docs.halofi.me" target="_blank" onClick={this.closeMenu} rel="noreferrer">
                          Docs
                        </a>
                      </li>
                      {!hideSignin && (
                        <li>
                          <a href="https://discord.gg/Y6bPpwbpK3" target="_blank" rel="noopener noreferrer">
                            Discord
                          </a>{' '}
                        </li>
                      )}
                    </ul>
                  </div>
                </nav>
              </>
            )}
            <div className="login-container">
              <WalletButton />
            </div>
          </div>
        </div>
        {/* {this.props.highlightHeader && !showEmergencyHeader && (
          <div className="banner-text">
            <p className="mb-0">
              Easily convert your fiat to crypto on our new{' '}
              <a id="banner-link" target="_blank" href="https://goodghosting.com/#/buy?utm=headerlink" rel="noreferrer">
                Buy page
              </a>{' '}
              !
            </p>
            <button type="button" className="alert-close" onClick={this.props.closeHighlightHeader}>
              X
            </button>
          </div>
        )} */}
        {this.props.highlightHeader && showEmergencyHeader && (
          <div className="banner-text-emergency">
            <p className="mb-0">
              ✔️ Deposits and withdrawals are{' '}
              <a target="_blank" href="https://twitter.com/goodghosting/status/1588110726031368192" rel="noreferrer">
                again enabled
              </a>{' '}
              . Thanks for your patience! ✔️
            </p>
            <button type="button" className="alert-close" onClick={this.props.closeHighlightHeader}>
              X
            </button>
          </div>
        )}
      </header>
    );
  }
}

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

const mapDispatchToProps = (dispatch) => {
  return {
    setIdentityName: (name) => dispatch(setIdentityName(name)),
  };
};
const mapStateToProps = (state) => ({ userAddress: state.user.address });

export default connect(mapStateToProps, mapDispatchToProps)(Header);
