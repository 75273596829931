import { createSelector } from '@reduxjs/toolkit';

const getGameState = (state) => state.game;

export const getGameInfo = createSelector(getGameState, (game) => game.info);
export const getGamesInfo = createSelector(getGameState, (game) => game.detail);
export const getGameStats = createSelector(getGameState, (game) => game.stats);
export const getGameDerived = createSelector(getGameState, (game) => game.derived);

// info
export const getCurrentSegment = createSelector(getGameInfo, (info) => info.currentSegment);
export const getNetworkId = createSelector(getGameInfo, (info) => info.networkId);
export const getNumberOfRounds = createSelector(getGameInfo, (info) => info.numberOfRounds);
export const getPaymentAmount = createSelector(getGameInfo, (info) => info.paymentAmount);
export const getPaymentCount = createSelector(getGameInfo, (info) => info.paymentCount);
export const getTotalSegments = createSelector(getGameInfo, (info) => info.totalSegmentCount);

export const getDepositToken = createSelector(getGameInfo, (info) => info.depositToken);
export const getDepositTokenAddress = createSelector(getGameInfo, (info) => info.depositTokenAddress);

export const getRewardToken = createSelector(getGameInfo, (info) => info.rewardToken);
export const getRewardTokenAddress = createSelector(getGameInfo, (info) => info.rewardTokenAddress);

export const getSecondaryRewardToken = createSelector(getGameInfo, (info) => info.secondaryRewardToken);
export const getSecondaryRewardTokenAddress = createSelector(getGameInfo, (info) => info.secondaryRewardTokenAddress);

export const getIncentiveToken = createSelector(getGameInfo, (info) => info.incentiveToken);
export const getIncentiveTokenAddress = createSelector(getGameInfo, (info) => info.incentiveTokenAddress);

// Stats
export const getRewardsValue = createSelector(getGameStats, (stats) => stats.rewards);
export const getSecondaryRewardsValue = createSelector(getGameStats, (stats) => stats.secondaryRewards);
export const getincentivesValue = createSelector(getGameStats, (stats) => stats.secondaryRewards);
export const getIsCapped = createSelector(getGameInfo, (info) => info.isCapped);

// derived
export const getFirstSegment = createSelector(getGameDerived, (derived) => derived.isFirstSegment);
export const getGameStarted = createSelector(getGameDerived, (derived) => derived.gameAlreadyStarted);
export const getLastSegment = createSelector(getGameDerived, (derived) => derived.lastSegment);
export const getNextSegmentEnd = createSelector(getGameDerived, (derived) => derived.nextSegmentEnd);
export const getLastSegmentEnd = createSelector(getGameDerived, (derived) => derived.lastSegmentEnd);

// stats
export const getIsGameFull = createSelector(getGameStats, (stats) => parseInt(stats.availableSpots) === 0);
export const getIsCompleted = createSelector(getGameStats, (stats) => stats.isCompleted);
export const getAvailableSpots = createSelector(getGameStats, (stats) => stats.availableSpots);
