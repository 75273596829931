import React, { useEffect, useRef, useState } from 'react';
import Image from 'components/elements/Image';
import classNames from 'classnames';
import Button from 'components/elements/Button';
import { useHistory } from 'react-router-dom';

import { parseBoolEnvVar } from 'utils/utilities';
import RampModal from 'views/Buy/components/RampModal';
import BetaSwitch from './BetaSwitch';

const shouldDisplayBetaSwitch = parseBoolEnvVar(process.env.REACT_APP_ENABLE_BETA_MODE);

function useClickOutside(ref, open, func) {
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (open && ref.current && !ref.current.contains(e.target)) {
        e.preventDefault();
        e.stopPropagation();

        func();
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [ref, open]);
}

export function WalletDropdown({ userAddress, open, logOut, close }) {
  const dropdownRef = useRef(null);
  useClickOutside(dropdownRef, open, () => close());
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <div ref={dropdownRef} className={classNames('wallet-dropdown', open && 'show')}>
        <div className="dropdown-title has-bottom-divider">
          Account
          <Button size="sm" onClick={logOut}>
            LOGOUT
          </Button>
        </div>
        <div className="dropdown-address">
          <Image className="dropdown-avatar" src={`https://robohash.org/${userAddress}`} />
          <p>{userAddress}</p>
        </div>

        <div className="dropdown-dashboard-button d-flex flex-column">
          <Button
            size="sm"
            color="dark"
            onClick={() => {
              close();
              history.push('/dashboard');
            }}
          >
            dashboard
          </Button>

          <Button
            className="mt-2"
            size="sm"
            color="dark"
            onClick={() => {
              close();
              setShowModal(true);
            }}
          >
            Buy or sell crypto
          </Button>
        </div>
        {shouldDisplayBetaSwitch && <BetaSwitch />}
      </div>
      <RampModal showModal={showModal} setShowModal={setShowModal} />
    </>
  );
}
