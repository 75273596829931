/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const gameSlice = createSlice({
  name: 'game',
  initialState: {
    info: {},
    stats: {},
    detail: {},
    derived: {},
  },
  reducers: {
    setGameInfo: (state, { payload }) => {
      state.info = payload;
    },
    setGame: (state, { payload }) => {
      state.detail = payload;
    },
    setGameStats: (state, { payload }) => {
      state.stats = payload;
    },
    updateGame: (state, { payload }) => {
      state.info = { ...state.info, ...payload };
    },
    updateStats: (state, { payload }) => {
      state.stats = { ...state.stats, ...payload };
    },
    setPaymentAmount: (state, { payload }) => {
      state.info.paymentAmount = payload;
    },
    setGameAlreadyStarted: (state, { payload }) => {
      state.derived.gameAlreadyStarted = payload;
    },
    setIsFirstSegment: (state, { payload }) => {
      state.derived.isFirstSegment = payload;
    },
    setLastSegment: (state, { payload }) => {
      state.derived.lastSegment = payload;
    },
    setFirstSegmentStart: (state, { payload }) => {
      state.derived.firstSegmentStart = payload;
    },
    setFirstSegmentEnd: (state, { payload }) => {
      state.derived.firstSegmentEnd = payload;
    },
    setNextSegmentEnd: (state, { payload }) => {
      state.derived.nextSegmentEnd = payload;
    },
    setLastSegmentEnd: (state, { payload }) => {
      state.derived.lastSegmentEnd = payload;
    },
    setAllSegmentTimes: (state, { payload }) => {
      state.derived.allSegmentTimes = payload;
    },
    resetGame: (state) => {
      state.info = {};
      state.detail = {};
      state.stats = {};
      state.derived = {};
    },
  },
});

export const {
  setGameInfo,
  setGameStats,
  updateGame,
  updateStats,
  setGameAlreadyStarted,
  resetGame,
  setIsFirstSegment,
  setLastSegment,
  setFirstSegmentStart,
  setFirstSegmentEnd,
  setNextSegmentEnd,
  setLastSegmentEnd,
  setAllSegmentTimes,
  setPaymentAmount,
  setGame,
} = gameSlice.actions;
export default gameSlice.reducer;
