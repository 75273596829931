/* eslint-disable react/no-children-prop */
/* eslint-disable react/no-string-refs */

import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter, Switch, HashRouter, useLocation } from 'react-router-dom';
import { resetFeedbacks } from 'redux/reducers/feedbacks';
import { setGameInfo } from 'redux/reducers/game';
import { setPlayerInfo } from 'redux/reducers/user';
import { setIsCelo, setPool } from 'redux/reducers/pool';
import { resetPlayersReducer } from 'redux/reducers/players';

// import Buy from 'views/Buy/components/Buy';
import AppRoute from './utils/AppRoute';
import ScrollReveal from './utils/ScrollReveal';
// import { ValoraModal } from './Models/wallet/ValoraModal';

// Layouts
// import LayoutDefault from './layouts/LayoutDefault';
import LayoutSignin from './layouts/LayoutSignin';
// import LayoutWithWeb3 from './layouts/LayoutWithWeb3';

// Views
// import Home from './views/Home';
// import Secondary from './views/Secondary';
// import Terms from './views/Terms';
// import GamesOverview from './views/GamesOverview';
// import GamesParent from './views/GamesParent';
// import Leaderboard from './views/Leaderboard/components/Leaderboard';

import MovedURL from './components/sections/MovedURL';
// import NewGame from './views/NewGame/NewGame';
// import PlayerDashboard from './views/PlayerDashboard/PlayerDashboard';

const App = () => {
  const scrollRevealRef = useRef();
  const [highlightHeader, setHighlightHeader] = useState(true);
  const location = useLocation();
  const closeHighlightHeader = () => {
    setHighlightHeader(false);
  };

  useEffect(() => {
    document.body.classList.add('is-loaded');
    scrollRevealRef.current.init();
  }, [location]);

  return (
    <ScrollReveal
      ref={scrollRevealRef}
      children={() => (
        <HashRouter>
          <ResetStoreOnRouteChange />
          {/* <ValoraModal /> */}
          <Switch>
            <AppRoute
              exact
              path="/"
              component={MovedURL}
              layout={LayoutSignin}
              highlightHeader={highlightHeader}
              closeHighlightHeader={closeHighlightHeader}
            />
            <AppRoute
              exact
              path="/2"
              component={MovedURL}
              layout={LayoutSignin}
              highlightHeader={highlightHeader}
              closeHighlightHeader={closeHighlightHeader}
            />
            <AppRoute
              exact
              path="/beta"
              component={MovedURL}
              layout={LayoutSignin}
              highlightHeader={highlightHeader}
              closeHighlightHeader={closeHighlightHeader}
            />
            {/*  <AppRoute
              exact
              path="/dashboard/:playerAddress"
              component={PlayerDashboard}
              layout={LayoutSignin}
              highlightHeader={highlightHeader}
              closeHighlightHeader={closeHighlightHeader}
            /> */}
            <AppRoute
              exact
              path="/dashboard"
              component={MovedURL}
              layout={LayoutSignin}
              highlightHeader={highlightHeader}
              closeHighlightHeader={closeHighlightHeader}
            />
            <AppRoute
              exact
              path="/pools/parent/"
              component={MovedURL}
              layout={LayoutSignin}
              highlightHeader={highlightHeader}
              closeHighlightHeader={closeHighlightHeader}
            />
            <AppRoute
              exact
              path="/pools"
              component={MovedURL}
              layout={LayoutSignin}
              highlightHeader={highlightHeader}
              closeHighlightHeader={closeHighlightHeader}
            />
            <AppRoute
              exact
              path="/pools/:id"
              component={MovedURL}
              layout={LayoutSignin}
              highlightHeader={highlightHeader}
              closeHighlightHeader={closeHighlightHeader}
            />
            <AppRoute
              exact
              path="/leaderboard/"
              component={MovedURL}
              layout={LayoutSignin}
              highlightHeader={highlightHeader}
              closeHighlightHeader={closeHighlightHeader}
            />
            <AppRoute
              exact
              path="/buy/"
              component={MovedURL}
              layout={LayoutSignin}
              highlightHeader={highlightHeader}
              closeHighlightHeader={closeHighlightHeader}
            />
            <AppRoute
              exact
              path="/litepaper"
              component={MovedURL}
              layout={LayoutSignin}
              highlightHeader={highlightHeader}
              closeHighlightHeader={closeHighlightHeader}
            />
            <AppRoute
              exact
              path="/terms"
              component={MovedURL}
              layout={LayoutSignin}
              highlightHeader={highlightHeader}
              closeHighlightHeader={closeHighlightHeader}
            />
          </Switch>
        </HashRouter>
      )}
    />
  );
};

export default withRouter((props) => <App {...props} />);

function ResetStoreOnRouteChange() {
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    dispatch(resetFeedbacks());
    dispatch(setPool({}));
    dispatch(setIsCelo(false));
    dispatch(setGameInfo({}));
    dispatch(setPlayerInfo({}));
    dispatch(resetPlayersReducer());
  }, [location, dispatch]);

  return null;
}
