/* eslint-disable import/no-unresolved */
import React, { useEffect, useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { UNSTOPPABLE_DOMAIN_SCOPE, getUDUsername } from 'rainbowkit/UDConnector';
import { EnsContext } from 'providers/EnsProvider';
import { Web3Context } from 'providers/Web3Provider';
import { getUserAddress, getIsConnectedCelo, getIsConnectedPolygon } from 'redux/selectors/user.selector';

export const IdentityContext = React.createContext();

export default function IdentityProvider({ children }) {
  const [identityName, setIdentityName] = useState('');
  const EnsClient = useContext(EnsContext);
  const Web3Client = useContext(Web3Context);
  const usersAddress = useSelector(getUserAddress);
  const isConnectedCelo = useSelector(getIsConnectedCelo);
  const isConnectedPolygon = useSelector(getIsConnectedPolygon);

  useEffect(() => {
    if (!usersAddress.length) {
      return;
    }

    const ensName = async (address, provider, ensAddress, tld) => {
      let ensResult;
      if (tld !== undefined) {
        ensResult = await EnsClient.getEnsName(address, provider, ensAddress, tld);
      } else {
        ensResult = await EnsClient.getEnsName(address);
      }
      return ensResult;
    };

    (async () => {
      // By default, sets the user address as its identity
      setIdentityName(usersAddress);

      // On CELO games, checks if user contains a Nomspace (.NOM) domain. If yes, uses it.
      if (isConnectedCelo && Web3Client.signer) {
        const { provider } = Web3Client.signer;
        const ensAddress = '0x3DE51c3960400A0F752d3492652Ae4A0b2A36FB3';
        const ensResult = await ensName(usersAddress, provider, ensAddress, 'nom');
        if (ensResult?.toLowerCase() !== usersAddress) {
          setIdentityName(`${ensResult}.nom`);
          return;
        }
      }

      // On POLYGON games, checks if users LOGGED IN with UnstoppableDomain (UD).
      // If yes, uses the UD username
      if (isConnectedPolygon) {
        // replaces all ' ' (empty space) with '+' and ':' with '%3A'.
        // ':' only occurs used once, so no RegEx needed to replace all.
        const udScope = UNSTOPPABLE_DOMAIN_SCOPE.replace(/\s/g, '+').replace(':', '%3A');
        const udUsername = await getUDUsername();

        const clientId = process.env.REACT_APP_UD_CLIENT_ID;
        // Sample authorization info
        // authorization?clientID=uauth_example_spa_id&scope=openid+wallet&username=udUsername
        const authCacheKey = `authorization?clientID=${encodeURIComponent(
          clientId
        )}&scope=${udScope}&username=${udUsername}`;
        let authInfo = localStorage.getItem(authCacheKey);
        if (authInfo) {
          try {
            authInfo = JSON.parse(authInfo);
          } catch (error) {
            authInfo = undefined;
          }

          const udWalletAddress = authInfo?.value?.idToken?.wallet_address?.toLowerCase() || '';
          if (udWalletAddress && udWalletAddress === usersAddress) {
            setIdentityName(udUsername.toLowerCase());
            return;
          }
        }
      }

      // If it couldn't resolve to either NOM nor UD domains, checks ENS
      // If user has an ENS, then uses it.
      try {
        const ensResult = await ensName(usersAddress);
        if (ensResult?.toLowerCase() !== usersAddress) {
          setIdentityName(ensResult.toLowerCase());
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
      }
    })();
    // DO NOT REENABLE THIS LINTER RULE FOR THIS LINE
    // IF WE DO SO AND ADD ALL THE DEPENDENCIES, IT WILL TRIGGER
    // RECURRING CHANGES IN STATE WITH RECURRING CALLS TO ENS, NOMSPACE OR UD
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersAddress, isConnectedCelo, isConnectedPolygon]);

  return <IdentityContext.Provider value={{ identityName }}>{children}</IdentityContext.Provider>;
}
