import React, { createContext, useState } from 'react';

export const HashContext = createContext();

export default function HashProvider({ children }) {
  const [hash, setHash] = useState('');
  const [withdrawPlayerInfo, setWithdrawPlayerInfo] = useState({});
  const [withdrawPoolInfo, setWithdrawPoolInfo] = useState({});
  const [withdrawGameInfo, setWithdrawGameInfo] = useState({});
  const [withdrawGameStats, setWithdrawGameStats] = useState({});
  const [withdrawnGames, setWithdrawnGames] = useState([]);
  const [darkTheme, setDarkTheme] = useState(false);

  return (
    <HashContext.Provider
      value={{
        hash,
        withdrawPlayerInfo,
        withdrawPoolInfo,
        withdrawGameInfo,
        withdrawGameStats,
        withdrawnGames,
        darkTheme,

        setHash,
        setWithdrawPlayerInfo,
        setWithdrawPoolInfo,
        setWithdrawGameInfo,
        setWithdrawGameStats,
        setWithdrawnGames,
        setDarkTheme,
      }}
    >
      {children}
    </HashContext.Provider>
  );
}
