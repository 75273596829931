/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { sdkJsClient } from 'service/sdk';
import { setUsersAddress } from 'redux/reducers/user';

import { FetchActionStatus } from 'redux/reduxConstants';

export const fetchPlayerGames = createAsyncThunk(
  'dashboard/fetchPlayerGames',
  async (usersAddress) => {
    const games = await sdkJsClient.getPlayerGames(usersAddress);

    return games;
  },
  {
    condition: (_, { getState }) => {
      const { loadingPlayerGames } = getState().dashboard;

      return loadingPlayerGames !== FetchActionStatus.Pending;
    },
  }
);

export const fetchPlayerGGScore = createAsyncThunk('dashboard/fetchPlayerGGScore', async (playerAddress) => {
  const response = await sdkJsClient.getPlayerGGScore(playerAddress);
  return response.ggScore;
});

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: {
    playerGames: [],
    loadingPlayerGames: FetchActionStatus.Idle,

    playerGGScore: '0',
    loadingPlayerGGScore: FetchActionStatus.Idle,
    isDashboardWithdrawing: false,
    externalLink: {},
    identityNames: {},
  },
  reducers: {
    setIsDashboardWithdrawing: (state, { payload }) => {
      state.isDashboardWithdrawing = payload;
    },
    setExternalLink: (state, { payload }) => {
      state.externalLink = payload;
    },
    setIdentityName: (state, { payload }) => {
      state.identityNames[payload.userAddress.toLowerCase()] = payload.userName;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(fetchPlayerGames.pending, (state) => {
        if (state.loadingPlayerGames === FetchActionStatus.Idle) {
          state.loadingPlayerGames = FetchActionStatus.Pending;
        }
      })
      .addCase(fetchPlayerGames.fulfilled, (state, action) => {
        const sortedGames = action.payload.sort((firstGame, secondGame) =>
          firstGame.gameStartsAt < secondGame.gameStartsAt ? 1 : -1
        );
        state.playerGames = sortedGames;
        state.loadingPlayerGames = FetchActionStatus.Fulfilled;
      })

      .addCase(fetchPlayerGGScore.pending, (state) => {
        state.loadingPlayerGGScore = FetchActionStatus.Pending;
      })
      .addCase(fetchPlayerGGScore.fulfilled, (state, action) => {
        state.playerGGScore = action.payload;
        state.loadingPlayerGGScore = FetchActionStatus.Fulfilled;
      })

      .addCase(setUsersAddress, (state) => {
        state.loadingPlayerGames = FetchActionStatus.Idle;
        state.loadingPlayerGGScore = FetchActionStatus.Idle;
      }),
});

export default dashboardSlice.reducer;

export const { setIsDashboardWithdrawing, setExternalLink, setIdentityName } = dashboardSlice.actions;
