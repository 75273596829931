import Image from 'components/elements/Image';
import React, { useState } from 'react';

export default () => {
  const SECOND = 1000;
  const [countdown, setCountdown] = useState(10 * SECOND);
  const decreaseOrRedirect = () => {
    if (countdown <= 0) {
      document.location.href = 'https://halofi.me';
      return;
    }

    setCountdown(countdown - SECOND);
  };

  setTimeout(decreaseOrRedirect, SECOND);

  return (
    <div
      className="d-flex flex-column align-items-center justify-content-center"
      style={{ paddingTop: '150px', paddingLeft: '5%', paddingRight: '5%' }}
    >
      <h4 style={{ textAlign: 'center', fontSize: '2rem', lineHeight: '2.5rem' }}>
        GoodGhosting is now{' '}
        <a href="https://halofi.me" rel="noopener noreferrer nofollow" style={{ color: '#fba35a' }}>
          HaloFi
        </a>
      </h4>
      <p style={{ textAlign: 'center' }}>{`You'll be redirected in ${String(countdown / SECOND).padStart(
        2,
        '0'
      )} seconds`}</p>
      <Image
        className="image"
        src={require('assets/rebrand-handover.gif')}
        alt="goodghosting handover"
        width={600}
        style={{ borderRadius: '9px' }}
      />
    </div>
  );
};
