import React from 'react';
import Resolution from '@unstoppabledomains/resolution';

export const UnstoppableDomainContext = React.createContext();

export default function UnstoppableDomainProvider({ children }) {
  async function getAddress(domain) {
    const resolution = new Resolution();
    return resolution.addr(domain, 'ETH');
  }

  return <UnstoppableDomainContext.Provider value={{ getAddress }}>{children}</UnstoppableDomainContext.Provider>;
}
