import React, { createContext, useState, useEffect, useContext, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isEqual } from 'lodash';
import { GasPriceOracle } from 'gas-price-oracle';
import { getNetworkId } from 'redux/selectors/game.selector';
import { getConnectedNetworkId } from 'redux/selectors/user.selector';
import { setCustomInput } from 'redux/reducers/feedbacks';
import { getNetworkName } from 'redux/selectors/pool.selector';
import {
  getShowWithdrawModal,
  getShowDepositModal,
  getShowConfirmModal,
  getShowEarlyWithdrawModal,
  getLoaderWithdraw,
  getLoaderEarlyWithdraw,
  getLoaderJoinGame,
  getLoaderMakeDeposit,
  getLoaderApproveDeposit,
  getCustomInput,
} from 'redux/selectors/feedbacks.selector';
import { celoID, networkData } from 'utils/networks';
import { HashContext } from 'providers/HashProvider';
import { truncateAndformat } from 'utils/numberFormat';
import { Web3Context } from './Web3Provider';

export const GasContext = createContext();

export default function GasProvider({ children }) {
  const [gasPrices, setGasPrices] = useState({});
  const [defaultChoice, setDefaultChoice] = useState(0);
  const [userChoice, setUserChoice] = useState(false);
  const [customFee, setCustomFee] = useState(0);
  const [toggle, setToggle] = useState(false);
  const [btnName, setBtnName] = useState('low');
  const [prevBtn, setPrevBtn] = useState('');
  const [show, setShow] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const { withdrawGameInfo, withdrawPoolInfo } = useContext(HashContext);
  const networkId = useSelector(getNetworkId) ?? withdrawGameInfo.networkId;
  const name = useSelector(getNetworkName) ?? withdrawPoolInfo.name;
  const userNetworkId = useSelector(getConnectedNetworkId) ?? withdrawGameInfo.networkId;
  const showDepositModal = useSelector(getShowDepositModal);
  const showWithdrawModal = useSelector(getShowWithdrawModal);
  const showEarlyWithdrawModal = useSelector(getShowEarlyWithdrawModal);
  const showConfirmModal = useSelector(getShowConfirmModal);
  const loaderWithdraw = useSelector(getLoaderWithdraw);
  const loaderEarlyWithdraw = useSelector(getLoaderEarlyWithdraw);
  const loaderJoinGame = useSelector(getLoaderJoinGame);
  const loaderMakeDeposit = useSelector(getLoaderMakeDeposit);
  const loaderApproveDeposit = useSelector(getLoaderApproveDeposit);
  const customInput = useSelector(getCustomInput);
  const { provider } = useContext(Web3Context);

  const dispatch = useDispatch();

  const prevGasRef = useRef();
  prevGasRef.current = gasPrices;

  const reset = () => {
    setDefaultChoice(0);
    setGasPrices({});
    setUserChoice(false);
    setCustomFee(0);
    setBtnName('low');
    setPrevBtn('low');
    if (show) {
      setShow(false);
    }
  };

  const fallbackGasPrices = {
    polygonID: {
      maxFeePerGas: 100,
      maxPriorityFeePerGas: 30,
    },
    celoID: {
      maxFeePerGas: 5,
      maxPriorityFeePerGas: 5,
    },
  };

  const gasPriceRange = {
    polygon: {
      floor: 30,
      ceiling: 500,
    },
    celo: {
      floor: 5,
      ceiling: 20,
    },
    default: {
      floor: 10,
      ceiling: 500,
    },
  };

  const priceRange = name !== undefined ? gasPriceRange[name] : gasPriceRange.default;
  const isTransacting =
    loaderEarlyWithdraw || loaderWithdraw || loaderJoinGame || loaderMakeDeposit || loaderApproveDeposit;

  useEffect(() => {
    let subscription;
    const fetchGasFees = async () => {
      const oracle = new GasPriceOracle({
        chainId: parseInt(networkId),
        defaultRpc: networkData[networkId].rpcURL,
        blocksCount: 1,
      });

      const oraclePrices = await oracle.gasPricesWithEstimate(fallbackGasPrices[parseInt(networkId)]);
      const adjustedGasPrices = { ...oraclePrices.gasPrices, ...oraclePrices.estimate };
      if (parseInt(networkId) === celoID) {
        // currently, gas estimations for celo network return highly over estimated prices.
        // so we manually lower them based on current network conditions.
        // DEV NOT: order of lines below are important, since behavior depends on each other.
        adjustedGasPrices.standard = adjustedGasPrices.baseFee + adjustedGasPrices.maxPriorityFeePerGas;
        adjustedGasPrices.instant =
          adjustedGasPrices.standard < adjustedGasPrices.low ? adjustedGasPrices.low : adjustedGasPrices.standard;
        adjustedGasPrices.low = adjustedGasPrices.baseFee;
      }
      if (!isEqual(prevGasRef.current, adjustedGasPrices)) {
        setDefaultChoice(adjustedGasPrices.low);
        setGasPrices((prevState) => ({ ...prevState, ...adjustedGasPrices }));
      }
    };

    const openModal = showDepositModal || showWithdrawModal || showEarlyWithdrawModal || showConfirmModal;

    if (networkId !== undefined && userNetworkId !== 0 && provider && openModal) {
      if (userNetworkId.toString() === networkId.toString() && !isTransacting) {
        subscription = () => {
          fetchGasFees();
        };
        provider.on('block', subscription);
      }
    }

    return () => {
      if (subscription) {
        provider.off('block', subscription);
      }
    };
  }, [
    networkId,
    userNetworkId,
    defaultChoice,
    provider,
    showDepositModal,
    showWithdrawModal,
    showEarlyWithdrawModal,
    showConfirmModal,
    loaderWithdraw,
    loaderEarlyWithdraw,
    loaderJoinGame,
    loaderMakeDeposit,
    loaderApproveDeposit,
    isTransacting,
    fallbackGasPrices,
  ]);
  const { standard, low, instant } = gasPrices;
  const { floor } = priceRange;

  const radios = [
    { name: 'Low', value: low },
    { name: 'Standard', value: standard },
    { name: 'Instant', value: instant },
  ];

  const modifier = (choice) => {
    if (Boolean(choice) && choice < floor) {
      return truncateAndformat(floor, 1);
    }

    return truncateAndformat(choice, 1) ?? choice;
  };

  const handlePopover = () => {
    setShow((prev) => !prev);
    if (toggle) {
      setToggle(false);
    }
    if (!customInput && prevBtn === 'custom' && customFee > floor) {
      dispatch(setCustomInput(true));
      setUserChoice(customFee);
      setBtnName('custom');
      return;
    }
    if (prevBtn === 'custom' && customFee < floor) {
      setBtnName('low');
      setUserChoice(gasPrices.low);
      return;
    }
    if (customInput && btnName !== 'custom') {
      dispatch(setCustomInput(false));
    }
    if (customInput && prevBtn === 'custom' && customFee > floor) {
      setUserChoice(customFee);
      return;
    }

    if (btnName !== 'custom' && show) {
      setUserChoice(gasPrices[prevBtn]);
    }

    if (!userChoice) {
      setBtnName('low');
    }
    // The setState below should always be at the bottom.
    setBtnName(prevBtn);
  };

  const handleConfirm = () => {
    if (customInput && customFee < floor) {
      setErrorMsg(`Minimum gas fee is ${floor}`);
      setTimeout(() => {
        setErrorMsg(false);
      }, 4000);
      return;
    }
    if (customInput) {
      setUserChoice(customFee);
      setBtnName('custom');
    } else {
      setUserChoice(gasPrices[btnName]);
    }
    setShow(false);
    setToggle(false);
  };

  const handleClick = () => {
    dispatch(setCustomInput(true));
    setBtnName('custom');
  };

  const handleToggleChange = (btn) => {
    if (customInput) {
      dispatch(setCustomInput(false));
    }
    if (customFee === '') {
      setCustomFee(0);
    }
    setToggle(true);
    setBtnName(btn.toLowerCase());
  };

  const handleCustomChange = (e, field) => {
    const { value } = e.target;
    const hasTwoOrLessDecimalPlaces = value.toString().includes('.')
      ? value.toString().split('.')[1].length <= 2
      : true;
    if (hasTwoOrLessDecimalPlaces) {
      field.onChange(e);
      setCustomFee(value);
    }
  };
  return (
    <GasContext.Provider
      value={{
        gasPrices,
        defaultChoice,
        userChoice,
        priceRange,
        isTransacting,
        radios,
        prevBtn,
        customFee,
        btnName,
        show,
        toggle,
        errorMsg,

        setDefaultChoice,
        reset,
        modifier,
        setUserChoice,
        setPrevBtn,
        setBtnName,
        setCustomFee,
        handlePopover,
        handleConfirm,
        handleClick,
        handleToggleChange,
        handleCustomChange,
      }}
    >
      {children}
    </GasContext.Provider>
  );
}
