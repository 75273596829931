import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Provider } from 'react-redux';
import { PersistGate } from 'reduxjs-toolkit-persist/integration/react';
import { persistStore } from 'reduxjs-toolkit-persist';
import store from 'redux/store';
import Web3Provider from 'providers/Web3Provider';
import GasProvider from 'providers/GasProvider';
import EnsProvider from 'providers/EnsProvider';
import IdentityProvider from 'providers/IdentityProvider';
import HashProvider from 'providers/HashProvider';
import UnstoppableDomainProvider from 'providers/UnstoppableDomainProvider';
import ArcxAnalyticsProvider from 'providers/AnalyticsProvider';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './assets/scss/main.scss';
import '@rainbow-me/rainbowkit/dist/index.css';

import { RainbowKitProvider, connectorsForWallets } from '@rainbow-me/rainbowkit';
import {
  metaMaskWallet,
  coinbaseWallet,
  braveWallet,
  walletConnectWallet,
  injectedWallet,
  omniWallet,
} from '@rainbow-me/rainbowkit/wallets';
import { configureChains, createClient, WagmiConfig } from 'wagmi';
import { polygon } from 'wagmi/chains';
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc';
import { Valora, CeloWallet, CeloDance } from '@celo/rainbowkit-celo/wallets';
import { Celo as CeloMainnet } from '@celo/rainbowkit-celo/chains';

import { networkData } from 'utils/networks';

import App from './App';
import * as serviceWorker from './serviceWorker';
import { UnstoppableDomains } from './rainbowkit/UDWallet';
// import { Venly } from './rainbowkit/VenlyWallet';

const celoMainnetConfig = { ...CeloMainnet, name: 'Celo' };

const { chains, provider } = configureChains(
  [celoMainnetConfig, polygon],
  [
    jsonRpcProvider({
      rpc: (chainId) => {
        return {
          http: networkData[chainId.id].rpcURL,
        };
      },
      static: false,
    }),
  ]
);

const connectors = connectorsForWallets([
  {
    groupName: 'Celo',
    wallets: [
      Valora({ chains }),
      metaMaskWallet({ chains }),
      coinbaseWallet({ chains }),
      braveWallet({ chains }),
      walletConnectWallet({ chains }),
      CeloWallet({ chains }),
      CeloDance({ chains }),
      omniWallet({ chains }),
      injectedWallet({ chains }),
    ],
  },
  {
    groupName: 'Polygon',
    wallets: [
      metaMaskWallet({ chains }),
      braveWallet({ chains }),
      walletConnectWallet({ chains }),
      coinbaseWallet({ chains }),
      UnstoppableDomains({ chains }),
      injectedWallet({ chains }),
      // Venly({ chains }),
    ],
  },
]);

const wagmiClient = createClient({
  autoConnect: false,
  connectors,
  provider,
});

const history = createBrowserHistory();
const persistor = persistStore(store);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <Router history={history}>
        <ArcxAnalyticsProvider>
          <WagmiConfig client={wagmiClient}>
            <RainbowKitProvider chains={chains}>
              <Web3Provider>
                <EnsProvider>
                  <UnstoppableDomainProvider>
                    <IdentityProvider>
                      <HashProvider>
                        <GasProvider>
                          <App />
                        </GasProvider>
                      </HashProvider>
                    </IdentityProvider>
                  </UnstoppableDomainProvider>
                </EnsProvider>
              </Web3Provider>
            </RainbowKitProvider>
          </WagmiConfig>
        </ArcxAnalyticsProvider>
      </Router>
    </PersistGate>
  </Provider>,

  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
