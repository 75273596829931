/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const pricefeedSlice = createSlice({
  name: 'pricefeed',
  initialState: {
    quotes: [],
  },
  reducers: {
    setTokenQuotes: (state, { payload }) => {
      state.quotes = payload;
    },
    resetTokenQuotes: (state) => {
      state.quotes = [];
    },
  },
});

export const { setTokenQuotes, resetTokenQuotes } = pricefeedSlice.actions;
export default pricefeedSlice.reducer;
