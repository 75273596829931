import { InjectedConnector } from 'wagmi/connectors/injected';
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect';
import UAuth from '@uauth/js';
import { Connector } from 'wagmi';

export const UNSTOPPABLE_DOMAIN_SCOPE = 'openid wallet';

const UD_OPTIONS = {
  clientID: process.env.REACT_APP_UD_CLIENT_ID,
  redirectUri: process.env.REACT_APP_UD_REDIRECT_URI,
  scope: UNSTOPPABLE_DOMAIN_SCOPE,
};

export const uauth = new UAuth(UD_OPTIONS);

export const getUDUsername = async () => {
  try {
    const user = await uauth.user();
    if (user) {
      return user.sub;
    }
    return null;
  } catch {
    return null;
  }
};

/** WAGMI connector for unstoppable domains
  
  This connector work as a thin proxy for the default wagmi connectors: InjectedConnector and WalletConnectConnector.
  All the defined connector functions are proxies for the corresponding underlying wagmi connector. 
  The first time a function is called it opens the UD modal. The user then logs in using their domain name. 
  After that, the appropriate connector is selected and UDConnector starts working as a simple proxy.
*/
export class UDConnector extends Connector {
  /** Unique connector id */
  id = 'udconnector';

  /** Connector name */
  name = 'Unstoppable Domains Connector';

  /** Whether connector is usable */
  ready = false;

  injectedProvider;

  walletConnectProvider;

  currentProvider = null;

  udPopupIsOpen = false;

  udPopupPromise;

  udUser = null;

  constructor(config) {
    super(config);

    const { chains } = config;

    const injectedConnector = new InjectedConnector({
      chains,
    });

    this.injectedProvider = injectedConnector;

    const walletConnectConnector = new WalletConnectConnector({
      chains,
      options: {
        qrcode: true,
      },
    });

    this.walletConnectProvider = walletConnectConnector;

    this.ready = true;
  }

  /** Open UD popup and select the appropriate provider based on the user choice */
  async loginUD() {
    try {
      await uauth.loginWithPopup();
    } catch (e) {
      console.error(e);
    }

    const user = await uauth.user();
    this.udUser = user;

    if (user.wallet_type_hint == null) {
      throw new Error('no wallet type present');
    }
    if (['web3', 'injected'].includes(user.wallet_type_hint)) {
      this.currentProvider = this.injectedProvider;
      return;
    }
    if (user.wallet_type_hint === 'walletconnect') {
      this.currentProvider = this.walletConnectProvider;
      return;
    }

    throw new Error('UD provider not supported');
  }

  /** This function makes sure that the OD popup is only opened once, even if two different connectors functions are called at the same time  */
  async openUDPopup() {
    if (!this.udPopupIsOpen && !this.isProviderConfigured()) {
      this.udPopupIsOpen = true;
      this.udPopupPromise = this.loginUD().finally(() => {
        this.udPopupIsOpen = false;
      });
    }

    return this.udPopupPromise;
  }

  isProviderConfigured() {
    return !!this.currentProvider;
  }

  async waitForUDReady() {
    if (!this.isProviderConfigured()) {
      /** Open OD login popup if provider is not configured, even if this is called multiple times the popup will only open once */
      await this.openUDPopup();
    }
  }

  async getUDUser() {
    await this.waitForUDReady();
    return this.udUser;
  }

  async proxyToConfiguredProvider(methodName, ...param) {
    await this.waitForUDReady();
    return this.currentProvider[methodName](...param);
  }

  async isAuthorized() {
    if (this.isProviderConfigured()) {
      return this.proxyToConfiguredProvider('isAuthorized');
    }
    return false;
  }

  async disconnect() {
    return this.proxyToConfiguredProvider('disconnect').finally(() => {
      this.currentProvider = null;
      this.udUser = null;
    });
  }

  async getProvider(config) {
    return this.proxyToConfiguredProvider('getProvider', config);
  }

  async getChainId() {
    return this.proxyToConfiguredProvider('getChainId');
  }

  async connect(config) {
    return this.proxyToConfiguredProvider('connect', config);
  }

  async getAccount() {
    return this.proxyToConfiguredProvider('getAccount');
  }

  async getSigner(config) {
    return this.proxyToConfiguredProvider('getSigner', config);
  }

  async switchChain(chainId) {
    return this.proxyToConfiguredProvider('switchChain', chainId);
  }

  async watchAsset(asset) {
    return this.proxyToConfiguredProvider('watchAsset', asset);
  }

  onAccountsChanged(accounts) {
    return this.proxyToConfiguredProvider('onAccountsChanged', accounts);
  }

  onChainChanged(chain) {
    return this.proxyToConfiguredProvider('onChainChanged', chain);
  }

  onDisconnect(error) {
    return this.proxyToConfiguredProvider('onDisconnect', error);
  }
}
