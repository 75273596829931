import imgCelo from 'assets/images/game/celo.svg';
import imgPolygon from 'assets/images/game/polygon.svg';
import imgEthereum from 'assets/images/game/ethereum.svg';
import imgCeloHeader from 'assets/images/game/celo-label.svg';
import imgPolygonHeader from 'assets/images/game/polygon-label.svg';

import { utils } from 'ethers';

// import WalletConnectProviderWeb3 from '@walletconnect/web3-provider';
// import celoImg from 'assets/logos/gg-logo-no-text.svg';
import flywalletLogo from 'assets/logos/flywallet.svg';
import openDAOLogo from 'assets/logos/opendao.svg';
import celostrialsLogo from 'assets/logos/celostrials.png';
import knoxDAOLogo from 'assets/logos/knox-dao.jpeg';
import cryptoColosseumLogo from 'assets/logos/crypto-colosseum.png';
import cheeLogo from 'assets/logos/chee_logo.png';
import ethichubLogo from 'assets/logos/ethichub.svg';
import symmetricLogo from 'assets/logos/symmetric.svg';
import spiralsLogo from 'assets/logos/spirals-logo.svg';

export const networksIcon = {
  celo: imgCelo,
  polygon: imgPolygon,
  kovan: imgEthereum,
  celoIconAndName: imgCeloHeader,
  polygonIconAndName: imgPolygonHeader,
};

// Add risk profile icon and disclaimers on games
// import { RiskProfiles } from './RiskProfiles';

const currencies = {
  alfajores: {
    cUSD: {
      address: '0x874069Fa1Eb16D44d622F2e0Ca25eeA172369bC1',
      name: 'cUSD',
    },
    cEUR: {
      address: '0x10c892a6ec43a53e45d0b916b4b7d383b1b78c0f',
      name: 'cEUR',
    },
  },
  celo: {
    cUSD: {
      address: '0x765de816845861e75a25fca122bb6898b8b1282a',
      name: 'cUSD',
    },
    mcUSD: {
      address: '0x918146359264C492BD6934071c6Bd31C854EDBc3',
      name: 'mcUSD',
    },
    cEUR: {
      address: '0xd8763cba276a3738e6de85b4b3bf5fded6d6ca73',
      name: 'cEUR',
    },
    cREAL: {
      address: '0xe8537a3d056DA446677B9E9d6c5dB704EaAb4787',
      name: 'cREAL',
    },
    mcREAL: {
      address: '0x9802d866fdE4563d088a6619F7CeF82C0B991A55',
      name: 'mcREAL',
    },
    celo: {
      address: '0x471EcE3750Da237f93B8E339c536989b8978a438',
      name: 'CELO',
    },
    mcCELO: {
      address: '0x7D00cd74FF385c955EA3d79e47BF06bD7386387D',
      name: 'mcCELO',
    },
  },
  polygon: {
    sos: {
      address: '0x8c059898ca6274750b6bF1cf38F2848347C490cc',
      name: '$SOS',
    },
    weth: {
      address: '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
      name: 'WETH',
    },
    amWeth: {
      address: '0x28424507fefb6f7f8e9d3860f56504e4e5f5f390',
      name: 'amweth',
    },
    wmatic: {
      address: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
      name: 'WMATIC',
    },
    amwMatic: {
      address: '0x8df3aad3a84da6b69a4da8aec3ea40d9091b2ac4',
      name: 'AMWMATIC',
    },
  },
};

export const polygonID = 137;
export const celoID = 42220;
export const kovanID = 42;
export const alfajoresID = 44787;
export const ethereumID = 1;

export const networkData = {
  [ethereumID]: {
    name: 'ethereum',
    rpcURL: process.env.REACT_APP_MAINNET_RPC,
  },

  [kovanID]: {
    name: 'kovan',
    nameWeb3: 'kovan',
    display: 'Kovan (testnet)',
    aaveLendingPoolProvider: '0x88757f2f99175387ab4c6a4b3067c77a695b0349',
    daiAddress: '0xFf795577d9AC8bD7D90Ee22b6C1703490b6512FD',
    aDaiAddress: '0xdCf0aF9e59C002FA3AA091a46196b37530FD48a8',
    // walletConnectProvider: WalletConnectProviderWeb3,
    baseExplorerURL: `https://kovan.etherscan.io/`,
    stableTokenLink:
      'https://staging.aave.com/#/faucet/0xff795577d9ac8bd7d90ee22b6c1703490b6512fd-0xff795577d9ac8bd7d90ee22b6c1703490b6512fd0x88757f2f99175387ab4c6a4b3067c77a695b0349',
    ethLink: 'https://ethdrop.dev/',
    stableToken: 'DAI',
    baseToken: 'kETH',
    networkId: kovanID,

    daiLink:
      'https://staging.aave.com/#/faucet/0xff795577d9ac8bd7d90ee22b6c1703490b6512fd-0xff795577d9ac8bd7d90ee22b6c1703490b6512fd0x88757f2f99175387ab4c6a4b3067c77a695b0349',
    rpcURL: process.env.REACT_APP_KOVAN_RPC,
    addNetworkToWallet: async () => {
      const data = [
        {
          chainId: utils.hexlify(kovanID),
        },
      ];
      await window.ethereum.request({ method: 'wallet_switchEthereumChain', params: data }).catch();
    },
  },
  // 80001: {
  //   name: "mumbai",
  //   nameWeb3: "mumbai",
  //   display: "Mumbai (testnet)",
  //   providerURL: `https://rpc-mumbai.maticvigil.com/v1/0fce9f98acaea94baab3ce74df45a4edc1852a7c`,
  //   aaveLendingPoolProvider: "0xd05e3E715d945B59290df0ae8eF85c1BdB684744",
  //   daiAddress: "0x001b3b4d0f3714ca98ba10f6042daebf0b1b7b6f",
  //   aDaiAddress: "0x27F8D03b3a2196956ED754baDc28D73be8830A6e",
  //   walletConnectProvider: WalletConnectProviderWeb3,
  //   baseExplorerURL: `https://explorer-mumbai.maticvigil.com/`,
  //   stableTokenLink:
  //     "https://testnet.aave.com/faucet/DAI-0xff795577d9ac8bd7d90ee22b6c1703490b6512fd0x88757f2f99175387ab4c6a4b3067c77a695b0349",
  //   ethLink: "https://faucet.matic.network/",
  //   stableToken: "DAI",
  //   baseToken: "MATIC",
  //   networkId: 80001,
  //   addNetworkToWallet: false,
  //   daiLink: "https://app.dev.pods.finance/utils",
  //   rpcURL: "https://rpc-mumbai.maticvigil.com/v1/0fce9f98acaea94baab3ce74df45a4edc1852a7c",
  // },
  [polygonID]: {
    name: 'polygon',
    nameWeb3: 'matic',
    networkId: polygonID,
    display: 'Polygon Mainnet',
    rpcURL: process.env.REACT_APP_POLYGON_RPC,
    aaveLendingPoolProvider: '0xd05e3E715d945B59290df0ae8eF85c1BdB684744',
    daiAddress: '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063',
    aDaiAddress: '0x27F8D03b3a2196956ED754baDc28D73be8830A6e',
    baseExplorerURL: `https://polygonscan.com/`,
    docsLink: 'https://docs.goodghosting.com/docs/guides/connecting/on-polygon-1',
    ethLink: 'https://docs.goodghosting.com/docs/guides/obtaining-polygon-tokens#how-to-obtain-matic-on-polygon',
    daiLink: 'https://docs.goodghosting.com/docs/guides/obtaining-polygon-tokens#how-to-obtain-dai-on-polygon',
    stableToken: 'DAI',
    baseToken: 'MATIC',
  },
  [alfajoresID]: {
    name: 'alfajores',
    nameWeb3: 'alfajores',
    networkId: alfajoresID,
    display: 'Alfajores (Testnet)',
    providerURL: 'https://alfajores-blockscout.celo-testnet.org/',
    rpcURL: 'https://alfajores-forno.celo-testnet.org',
    aaveLendingPoolProvider: '0x6EAE47ccEFF3c3Ac94971704ccd25C7820121483',
    daiAddress: currencies.alfajores.cUSD.address,
    aDaiAddress: '0x32974C7335e649932b5766c5aE15595aFC269160',
    baseExplorerURL: `https://alfajores-blockscout.celo-testnet.org/`,
    docsLink: 'https://docs.goodghosting.com/docs/guarded-launch/celo-savings-pools',
    ethLink: 'https://celo.org/developers/faucet',
    daiLink: 'https://celo.org/developers/faucet',
    stableToken: currencies.alfajores.cUSD.name,
    baseToken: 'CELO',
  },
  [celoID]: {
    name: 'celo',
    nameWeb3: 'celo',
    networkId: celoID,
    display: 'Celo',
    providerURL: process.env.REACT_APP_CELO_RPC,
    rpcURL: process.env.REACT_APP_CELO_RPC,
    aaveLendingPoolProvider: '0xD1088091A174d33412a968Fa34Cb67131188B332',
    daiAddress: currencies.celo.cUSD.address,
    aDaiAddress: currencies.celo.mcUSD.address,
    // walletConnectProvider: WalletConnectProviderWeb3,
    baseExplorerURL: `https://explorer.celo.org/`,
    docsLink: 'https://docs.goodghosting.com/docs/guarded-launch/celo-savings-pools',
    ethLink: 'https://docs.goodghosting.com/docs/guides/obtaining-cusd-and-celo#how-to-obtain-celo',
    daiLink: 'https://docs.goodghosting.com/docs/guides/obtaining-cusd-and-celo#how-to-obtain-cusd-on-celo',
    stableToken: 'cUSD',
    baseToken: 'CELO',
  },
};

export const networkIdNames = {
  1: 'Ethereum',
  42: 'Kovan',
  137: 'Polygon',
  44787: 'Alfajores',
  42220: 'Celo',
};

export const hasWaitingRound = true;

export const sponsorsConfig = {
  flywallet: {
    logo: flywalletLogo,
    description:
      'Flywallet is ready to help savers take off! They will be providing Book Now, Fly Later vouchers to pool winners. One special saver will be upgraded to a full flight credit!',
    url: 'https://www.flywallet.io/',
    extraDescription: 'website',
    extraUrl: 'https://www.flywallet.io/',
  },
  openDAO: {
    logo: openDAOLogo,
    description:
      'OpenDAO will allocate $2000 worth of $SOS tokens, to be shared between all successful savers in this GoodGhosting pool.',
    url: 'https://www.theopendao.com/#/',
  },
  celostrials: {
    logo: celostrialsLogo,
    description: 'Celostrials nfETs',
    url: 'https://celostrials.com/#/',
  },
  knoxDAO: {
    logo: knoxDAOLogo,
    description: 'Knox DAO - a Learn2Earn DAO',
    url: 'https://www.knoxdao.xyz/',
  },
  cryptoColosseum: {
    logo: cryptoColosseumLogo,
    description:
      'Play and Earn prestige by backing NFT gladiators, Enjoy the whimsical violence. You get six free bets for signing up.',
    url: 'https://www.cryptocolosseum.com/',
  },
  cheeFinance: {
    logo: cheeLogo,
    url: 'https://www.chee.finance/',
    description: 'Chee Finance is building the most advanced web3 money market, with ERC20 / NFT liquidity.',
  },
  ethichub: {
    logo: ethichubLogo,
    url: 'https://www.ethichub.com/',
    description:
      'Ethichub improves the living standard of small farming communities by investing in their projects, buying their coffee, or providing liquidity for their loans. Now you can quickly, safely, and easily generate great social impact.',
  },
  symmetric: {
    logo: symmetricLogo,
    url: 'https://symmetric.finance/',
    description: 'Symmetric is the most flexible, efficient, and secure DeFi trading and investing platform on Celo.',
  },
  spirals: {
    logo: spiralsLogo,
    url: 'https://spirals.so/',
    description: 'Spirals empowers you to make costless climate action through turning your crypto green.',
  },
};

/*
**** Notes ***

- rewardToken is the tokens recieved from the lending protocol itself
- rewardToken2 is an addition token added to the pool manually
- poolInterest is interest generated in the "stableToken" - note that we will start using volatile tokens as well

*/
