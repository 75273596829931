import { truncate, weiToERC20, toBN } from 'utils/utilities';
import { bigNumberFrom } from 'utils/bignumber';

export const MaxDecimalPrecision = {
  matic: 3,
  wmatic: 3,
  eth: 6,
  weth: 6,
  dai: 3,
  crv: 3,
  cusd: 2,
  celo: 3,
  flipp3ning: 2,
  wptg: 2,
  must: 2,
  bank: 2,
  percentage: 2,
  ghst: 3,
  usd: 2,
  gdai: 5,
  usdc: 3,
  cmt: 3,
  link: 3,
};

export const MinDecimalPrecision = {
  matic: 1,
  wmatic: 1,
  eth: 2,
  weth: 2,
  dai: 0,
  crv: 1,
  cusd: 0,
  celo: 0,
  flipp3ning: 1,
  wptg: 0,
  must: 2,
  bank: 0,
  percentage: 2,
  ghst: 1,
  usd: 2,
  gdai: 0,
  usdc: 0,
  cmt: 0,
  link: 2,
};

const DefaultPrecision = 1;

export function format(val, coin, options = {}) {
  const coinName = coin && coin.toLowerCase();

  const customFormatting = options[coinName] ?? {};

  const minimumFractionDigits =
    customFormatting.minimumFractionDigits ?? MinDecimalPrecision[coinName] ?? DefaultPrecision;

  const maximumFractionDigits =
    customFormatting.maximumFractionDigits ?? MaxDecimalPrecision[coinName] ?? DefaultPrecision;

  return formatToMaxAndMinDigits(val, minimumFractionDigits, maximumFractionDigits);
}

export function formatWei(val, coin, options) {
  return format(weiToERC20(toBN(val).toString()), coin, options);
}

export function truncateAndformatWei(val, precision) {
  const truncatedVal = truncate(weiToERC20(val), precision);

  return formatToMaxAndMinDigits(truncatedVal, precision, precision);
}

export function truncateAndformat(val, precision) {
  const truncatedVal = truncate(val, precision);

  return formatToMaxAndMinDigits(truncatedVal, precision, precision);
}

export function truncateAndFormatCoin(val, coin, options = {}) {
  const coinName = coin && coin.toLowerCase();

  const customFormatting = options[coinName] ?? {};

  const minimumFractionDigits =
    customFormatting.minimumFractionDigits ?? MinDecimalPrecision[coinName] ?? DefaultPrecision;

  const maximumFractionDigits =
    customFormatting.maximumFractionDigits ?? MaxDecimalPrecision[coinName] ?? DefaultPrecision;

  const truncatedVal = truncate(val, maximumFractionDigits);

  return formatToMaxAndMinDigits(truncatedVal, minimumFractionDigits, maximumFractionDigits);
}

export function truncateAndformatWeiWithNoZero(val, precision) {
  const truncatedVal = truncate(weiToERC20(val), precision);

  return truncatedVal;
}

export function formatToMaxAndMinDigits(val, minimumFractionDigits, maximumFractionDigits) {
  const numberStr = String(val);
  if (!numberStr.includes('.')) {
    return val;
  }

  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits,
    maximumFractionDigits,
  })
    .format(Number(val))
    .replaceAll(',', '');
}

export function getMaxPrecision(coin) {
  return Number(MaxDecimalPrecision[String(coin)?.toLowerCase()] || DefaultPrecision);
}

export function getMinAmountForCoin(coin) {
  const precision = getMaxPrecision(coin);
  return bigNumberFrom(10).exponentiatedBy(bigNumberFrom(precision).negated());
}
