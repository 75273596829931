import React, { useState } from 'react';
import Switch from 'react-switch';

import { sdkJsClient } from 'service/sdk';
import { useSelector } from 'react-redux';
import Loading from 'components/elements/Loading';

const NetlifyCookieName = 'nf_ab';

const { REACT_APP_BETA_BRANCH_NAME } = process.env;
const REACT_APP_GG_SCORE_BETA_MODE = Number(process.env.REACT_APP_GG_SCORE_BETA_MODE);

function setToBetaVersion() {
  const expires = new Date(Date.now() + 1000 * 60 * 60 * 24 * 365); // 1y in ms
  const cookie = `${NetlifyCookieName}=${REACT_APP_BETA_BRANCH_NAME}; expires=${expires.toUTCString()};`;
  document.cookie = cookie;
  window.location.reload(true);
}

function setToRegularVersion() {
  const cookie = `${NetlifyCookieName}=; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
  document.cookie = cookie;
  window.location.reload(true);
}

function getBetaCookie() {
  const cookies = document.cookie ?? '';
  return cookies
    .split('; ')
    .find((row) => row.startsWith(`${NetlifyCookieName}=`))
    ?.split('=')?.[1];
}

function BetaSwitch() {
  const [showBetaSwitch, setShowBetaSwitch] = useState(false);
  const [checked, setChecked] = useState(getBetaCookie() === REACT_APP_BETA_BRANCH_NAME);
  const [isBetaLoading, setIsBetaLoading] = useState(false);
  const [betaError, setBetaError] = useState('');

  const usersAddress = useSelector((state) => state.user.address);

  const handleBetaMode = async (isChecked) => {
    setBetaError('');
    setChecked(isChecked);
    setIsBetaLoading(true);

    let ggScore = 0;
    try {
      const result = await sdkJsClient.getPlayerGGScore(usersAddress);
      ggScore = result.ggScore ?? 0;
    } catch {
      setChecked(false);
      setIsBetaLoading(false);
      setBetaError('Something went wrong');
      return;
    }

    if (ggScore < REACT_APP_GG_SCORE_BETA_MODE) {
      setChecked(false);
      setIsBetaLoading(false);
      setBetaError(`🚫 Sorry, a GG Score of ${REACT_APP_GG_SCORE_BETA_MODE} is required 🚫`);
      return;
    }

    if (isChecked) {
      setToBetaVersion();
    } else {
      setToRegularVersion();
    }
  };
  return (
    <div className="w-100">
      <div
        className="dropdown-show-more"
        onKeyDown={() => setShowBetaSwitch(!showBetaSwitch)}
        onClick={() => setShowBetaSwitch(!showBetaSwitch)}
        role="button"
        tabIndex="0"
      >
        <div className="arrow-down-buton">
          <div className="arrow-down" />
        </div>
      </div>
      {showBetaSwitch && (
        <>
          <label className="beta-switch-container" htmlFor="beta-switch">
            <span>Beta Mode</span>
            <Switch
              id="beta-switch"
              offColor="#473d7e"
              onColor="#8e79fc"
              onChange={handleBetaMode}
              checked={checked}
              uncheckedIcon={false}
              checkedIcon={false}
            />
            {isBetaLoading && <Loading height={30} width={30} type="ThreeDots" />}
          </label>
          <span className="red-text d-block mt-15">{betaError}</span>
        </>
      )}
    </div>
  );
}

export default BetaSwitch;
