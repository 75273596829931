import React from 'react';
import classNames from 'classnames';

import Loader from 'react-loader-spinner';

const Loading = ({ classes, type = 'Puff', color = '#8E79FC', height = 70, width = 70 }) => {
  const loaderClass = classNames(classes);
  return <Loader className={loaderClass} type={type} color={color} height={height} width={width} />;
};

export default Loading;
