import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const propTypes = {
  children: PropTypes.node,
  handleClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  closeHidden: PropTypes.bool,
  video: PropTypes.string,
  videoTag: PropTypes.oneOf(['iframe', 'video']),
  className: PropTypes.string,
  backButtonLabel: PropTypes.string,
  handleGoBack: PropTypes.func.isRequired,
};

const defaultProps = {
  children: null,
  closeHidden: false,
  video: '',
  videoTag: 'iframe',
  className: '',
  backButtonLabel: null,
};

class Modal extends React.Component {
  componentDidMount() {
    document.addEventListener('keydown', this.keyPress);
    document.addEventListener('click', this.stopProgagation);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.show !== this.props.show) {
      this.handleBodyClass();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.keyPress);
    document.removeEventListener('click', this.stopProgagation);
  }

  handleBodyClass = () => {
    if (document.querySelectorAll('.modal.is-active').length) {
      document.body.classList.add('modal-is-active');
    } else {
      document.body.classList.remove('modal-is-active');
    }
  };

  keyPress = (e) => {
    if (e.keyCode === 27) {
      this.props.handleClose(e);
    }
  };

  stopProgagation = (e) => {
    e.stopPropagation();
  };

  render() {
    const {
      className,
      children,
      handleClose,
      show,
      closeHidden,
      video,
      videoTag,
      backButtonLabel,
      handleGoBack,
      ...props
    } = this.props;

    const classes = classNames('modal', show && 'is-active', video && 'modal-video', className);

    return (
      <>
        {show && (
          <div role="presentation" {...props} className={classes} onClick={handleClose}>
            <div role="presentation" className="modal-inner" onClick={this.stopProgagation}>
              {video ? (
                <div className="responsive-video">
                  {videoTag === 'iframe' ? (
                    <iframe title="video" src={video} frameBorder="0" allowFullScreen />
                  ) : (
                    <video controls src={video} />
                  )}
                </div>
              ) : (
                <>
                  {!closeHidden && (
                    <button type="button" className="modal-close" aria-label="close" onClick={handleClose} />
                  )}
                  <div className="modal-content">
                    <button className="modal-x modal-right-button" onClick={handleClose} type="button">
                      X
                    </button>

                    {backButtonLabel && (
                      <button className="modal-x modal-left-button button-primary" onClick={handleGoBack} type="button">
                        {backButtonLabel}
                      </button>
                    )}
                    {children}
                  </div>
                </>
              )}
            </div>
          </div>
        )}
      </>
    );
  }
}

Modal.propTypes = propTypes;
Modal.defaultProps = defaultProps;

export default Modal;
