export const PLAYERS_PER_PAGE = 100;
export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000';

export const ERRORS = {
  RECORD_NOT_FOUND: 'RECORD_NOT_FOUND',
};

export const ROUND_MEASURE = {
  Hours: 'hours',
  Days: 'days',
  Weeks: 'weeks',
  Months: 'months',
};

export const MECHANISM_TYPE = {
  Saving: 'saving',
  Hodl: 'hold',
};

export const DEPOSIT_TYPE = {
  Fixed: 'fixed',
  Flexible: 'flexible',
};

export const TAG_TOOL_TIP = {
  hold: `Make one deposit and do not withdraw it before the end date, to complete this challenge. Get motivated to "Hold On for Dear Life" (HODL).`,
  saving: 'Savings challenges require multiple deposits. They help you build up the good habit of saving regularly.',
  fixed: `Deposit amounts are the same for all players in this pool (fixed deposit amount).`,
  custom: `Deposit amounts are chosen by each player when joining this challenge (custom deposit amounts).`,
  stable: `This savings challenge only has exposure to stablecoins (pegged to a fiat currency).`,
  volatile: `This savings challenge has price exposure to one or more volatile assets, and is susceptible to market volatility.`,
};

export const ABI = {
  v001: '0.0.1',
  v002: '0.0.2',
  v003: '0.0.3',
  v200: '2.0.0',
  v201: '2.0.1',
  v202: '2.0.2',
  v203: '2.0.3',
};

export const ABIVersions = {
  v20x: [ABI.v200, ABI.v201, ABI.v202, ABI.v203],
};

export const STRATEGY_PROVIDER = {
  Aave: 'aave',
  Moola: 'moola',
  Curve: 'curve', // used by V2 contracts
  CurveAave: 'curve-aave', // used by V1 contracts
  CurveAtricrypto: 'curve-atricrypto', // used by V1 contracts
  CurveMatic: 'curve-matic-stmatic', // used by V1 contracts
  Mobius: 'mobius',
  Open: 'open',
};

export const TRANSACTION_TYPE = {
  JoinGame: 'joinGame',
  Approve: 'approve',
  ExternalPoolDeposit: 'depositIntoExternalPool',
  EarlyWithdraw: 'earlyWithdraw',
  Join: 'join',
  JoinWhitelistedGame: 'joinWhitelistedGame',
  JoinWhiteList: 'joinWhiteList',
  MakeDeposit: 'makeDeposit',
  Deposit: 'deposit',
  RedeemFromExternalPool: 'redeemFromExternalPool',
  Redeem: 'redeem',
  Withdraw: 'withdraw',
};

export const SLIPPAGE_STRATEGIES = [
  STRATEGY_PROVIDER.CurveAave,
  STRATEGY_PROVIDER.CurveAtricrypto,
  STRATEGY_PROVIDER.CurveMatic,
  STRATEGY_PROVIDER.Curve,
  STRATEGY_PROVIDER.Mobius,
];

export const REGISTRY = {
  unstoppableDomain: 'uns',
  ensDomain: 'ens',
};

export const ENS_REGISTRY = {
  eth: REGISTRY.ensDomain,
  nom: REGISTRY.ensDomain,
};

export const UNSTOPPABLE_DOMAIN_REGISTRY = {
  crypto: REGISTRY.unstoppableDomain,
  wallet: REGISTRY.unstoppableDomain,
  blockchain: REGISTRY.unstoppableDomain,
  dao: REGISTRY.unstoppableDomain,
};

export const UNIX_MINUTES_EPOCH_CONVERSION = 1000;
export const MAX_GAS_PRICE = 10000000;
export const ENS_ADDRESS = '0x3DE51c3960400A0F752d3492652Ae4A0b2A36FB3';
