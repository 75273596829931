/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const playersSlice = createSlice({
  name: 'players',
  initialState: {
    offset: 0,
    list: [],
    lastAction: 'load',
    skip: 0, // skip already fetched from graph
    fetched: false,
    nft: [],
    stats: {
      winners: 0,
      ghosts: 0,
      dropouts: 0,
      waitings: 0,
    },
  },
  reducers: {
    setPlayers: (state, { payload }) => {
      state.list = payload;
    },
    setFetched: (state) => {
      state.fetched = true;
    },
    setPlayersStats: (state, { payload }) => {
      state.stats = payload;
    },
    setSkip: (state, { payload }) => {
      state.skip = payload;
    },
    updatePlayersStats: (state, { payload }) => {
      state.stats[payload.key] = payload.value;
    },
    setOffset: (state, { payload }) => {
      state.offset = payload;
    },
    setLastAction: (state, { payload }) => {
      state.lastAction = payload;
    },
    setPlayersCategories: (state, { payload }) => {
      state.stats.winners = ~~payload.winners;
      state.stats.waiting = ~~payload.waiting;
      state.stats.ghosts = ~~payload.ghosts;
      state.stats.dropouts = ~~payload.dropouts;
    },
    setNft: (state, { payload }) => {
      // should use this specific order to avoid players with more than one
      // nft changing pfp as player moves from "game stats" tab to "players" tab
      state.nft = { ...payload, ...state.nft };
    },
    resetPlayersReducer: (state) => {
      state.offset = 0;
      state.list = [];
      state.nft = [];
      state.fetched = false;
      state.lastAction = 'load';
      state.stats = {
        winners: 0,
        ghosts: 0,
        dropouts: 0,
        waitings: 0,
      };
    },
  },
});

export const {
  setPlayers,
  setNft,
  setOffset,
  resetPlayersReducer,
  setPlayersStats,
  updatePlayersStats,
  setPlayersCategories,
  setFetched,
  setSkip,
  setLastAction,
} = playersSlice.actions;
export default playersSlice.reducer;
