/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { UNIX_MINUTES_EPOCH_CONVERSION } from 'utils/constants';

export const poolSlice = createSlice({
  name: 'pool',
  initialState: {
    info: {},
    isCelo: false,
  },
  reducers: {
    setPool: (state, { payload }) => {
      const { gameStartsAt, gameClosesAt, gameEndsAt } = payload;
      state.info = {
        ...payload,
        gameStartsOn: gameStartsAt && parseInt(gameStartsAt) * UNIX_MINUTES_EPOCH_CONVERSION,
        gameClosesAt: gameClosesAt && parseInt(gameClosesAt) * UNIX_MINUTES_EPOCH_CONVERSION,
        gameEndsAt: gameEndsAt && parseInt(gameEndsAt) * UNIX_MINUTES_EPOCH_CONVERSION,
        isNewSubgraph: payload.isUsingTheGraph && payload.graphVersion === '0.0.2',
      };
    },
    setIsCelo: (state, { payload }) => {
      state.isCelo = payload;
    },
    resetPool: (state) => {
      state.info = {};
      state.isCelo = false;
    },
  },
});

export const { setPool, setIsCelo, resetPool } = poolSlice.actions;
export default poolSlice.reducer;
