import { createSelector } from '@reduxjs/toolkit';
import { SLIPPAGE_STRATEGIES } from 'utils/constants';

const getPoolState = (state) => state.pool;

export const getPoolInfo = createSelector(getPoolState, (pool) => pool.info);
export const getIsCelo = createSelector(getPoolState, (pool) => pool.isCelo);

export const getAddToCalendar = createSelector(getPoolInfo, (info) => info.addToCalendar);
export const getContract = createSelector(getPoolInfo, (info) => info.contract);
export const getDaiAddress = createSelector(getPoolInfo, (info) => info.daiAddress);
export const getGameNumber = createSelector(getPoolInfo, (info) => info.gameNumber);
export const getIsNewSubgraph = createSelector(getPoolInfo, (info) => info.isNewSubgraph);
export const getMaxPlayers = createSelector(getPoolInfo, (info) => info.maxPlayers);
export const getNetworkId = createSelector(getPoolInfo, (info) => info.networkId);
export const getEarlyWithdrawFee = createSelector(getPoolInfo, (info) => info.earlyWithdrawFee);
export const getGameName = createSelector(getPoolInfo, (info) => info.gameName);
export const getNetworkName = createSelector(getPoolInfo, (info) => info.name);
export const getBlogPostUri = createSelector(getPoolInfo, (info) => info.blogPostUri);
export const getExplorerURL = createSelector(getPoolInfo, (info) => info.baseExplorerURL);

export const isSlippageStrategy = createSelector(getPoolInfo, (info) =>
  SLIPPAGE_STRATEGIES.includes(info.strategyProvider)
);
export const hasRiskProfile = createSelector(getPoolInfo, (info) => !!info.riskProfile);

// STABLE
export const getStableToken = createSelector(getPoolInfo, (info) => info?.stableToken || '');
export const getStableTokenAddress = createSelector(getPoolInfo, (info) => info?.daiAddress || '');

// REWARD
export const getRewardToken = createSelector(getPoolInfo, (info) => info?.rewardToken || '');
export const getRewardsTokenAddress = createSelector(getPoolInfo, (info) => info?.rewardsTokenAddress || '');

// REWARD2
export const getRewardToken2 = createSelector(getPoolInfo, (info) => info?.rewardToken2 || '');
export const getRewardsTokenAddress2 = createSelector(getPoolInfo, (info) => info?.rewardsTokenAddress2 || '');
